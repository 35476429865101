
import { Icon } from "@mui/material";
import Logout from "auth/Logout";
import Profiles from "layouts/profiles";
import Activiy from "layouts/activities";
import Users from "layouts/users";
import City from "layouts/cities";
import Advertisings from "layouts/advertisings";

const routes = [
  { type: "title", title: "CATÁLOGOS", key: "account-pages" },
  {
    type: "collapse",
    name: "Perfiles",
    key: "profiles",
    route: "/profiles",
    icon: <Icon color="white">person_3</Icon>,
    component: <Profiles />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Ciudades",
    key: "ciudades",
    route: "/ciudades",
    icon: <Icon color="white">apartment</Icon>,
    component: <City />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Publicidad",
    key: "publicidad",
    route: "/publicidad",
    icon: <Icon color="white">desktop_windows</Icon>,
    component: <Advertisings />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Usuarios Internos",
    key: "usuarios",
    route: "/usuarios",
    icon: <Icon color="white">people</Icon>,
    component: <Users />,
    noCollapse: true,
  },
  { type: "title", title: "ADMINISTRADOR", key: "account-pages" },
  {
    type: "collapse",
    name: "Actividad",
    key: "actividad",
    route: "actividad",
    icon: <Icon color="white">web_stories</Icon>,
    component: <Activiy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Cerrar Sesión",
    key: "login",
    route: "logout",
    icon: <Icon color="white">logout</Icon>,
    component: <Logout />,
    noCollapse: true,
  },
];

export default routes;
