
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { Divider, Grid, Icon, Modal } from "@mui/material";
import { useState, useEffect } from "react";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import { getData } from "tools/helpers";

function Activiy() {
  const [data, setData] = useState([]);

  const columns = [
    {
      name: '',
      selector: row => <Grid container alignItems="center"><Icon color={row.desc_2} >info</Icon><SoftTypography fontSize={12} p={0} mr={1} ml={1} fontWeight="medium">{row.desc_1} en <b>{row.module}</b> por {row.name}</SoftTypography><SoftTypography fontSize={10} p={0} style={{ color: 'gray'}} fontWeight="medium">{row.date_time} </SoftTypography></Grid>,
      compact: true,
    },
  ];


  const tableData = {
    columns,
    data,
  };

  const getHistoryLog = async () => {
    const route = `History-Log`;
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data);
    }
  };


  useEffect(()=>{
    getHistoryLog();
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Actividad </SoftTypography>
              <DataTableExtensions
                {...tableData}
                export={false}
                print={false}
                filterPlaceholder="Buscar..."
                filter={false}
              >
                <DataTable
                    pagination
                    paginationComponentOptions={{
                      rowsPerPageText: 'Registros por página:', 
                      rangeSeparatorText: 'de', 
                      selectAllRowsItem: true, 
                      selectAllRowsItemText: 'Todos',
                    }}
                    paginationPerPage={20}
                    noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
                />
              </DataTableExtensions>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Activiy;
