
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";

// Data
import { Divider, Grid, Input, Icon, Modal } from "@mui/material";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import imageCompression from 'browser-image-compression';

import SoftAlert from "components/SoftAlert";
import { useState, useEffect } from "react";
import SoftButton from "components/SoftButton";

import { insertDataJson, getData, updateItem, deleteItem, validateForm } from "tools/helpers";

function DataForm() {
  const emptyData = {
    customer : '',
    location : 'Inicio',
    image : '',
    url : '',
    order : '',
  }
  const [formData, setFormData] = useState(emptyData);

  const [status, setStatus] = useState({status: null, message: ""});
  const [isEdit, setIsEdit] = useState(false);
  const [errorFields, setErrorsFields] = useState("");
  const [data, setData] = useState([]);
  const [dataStates, setDataStates] = useState([]);


  const handleFile = async (event) => {
    setStatus({
      mostrar: true,
      mensaje: 'Comprimiento imagen....',
      etiqueta: 'info'
    });

    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); 
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); 
      console.log(compressedFile);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile); 
      reader.onloadend = function() {
        const imgBase64data = reader.result;                
        setFormData({
          ...formData,
          image: imgBase64data,
        });
        setStatus({
          mostrar: false,
          mensaje: '',
          etiqueta: ''
        });
      }

    } catch (error) {
      console.log(error);
    }
  }
  
  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function selectLocation(location) {
    setFormData({
      ...formData,
      location: location.value,
    })
  }

  const getAdvertisings = async () => {
      const route = `Advertisings`;
      const send = await getData(route);
      if (send.code === 200) {
        setData(send.data);
      }
  };

  const sendForm = async() =>{
    let requiredFields = ['customer', 'order', 'location', 'image'];

    if(isEdit){
      requiredFields = ['customer', 'order', 'location'];
    }

    const validationErrors = validateForm(formData, requiredFields);
    if(formData.state_id === 0){
      validationErrors.push('state_id');
    }
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatus({status: true, message: "Guardando..."});
    const route = `Advertisings`;
    let send = null;

    if(isEdit){
      send = await updateItem(`${route}/${formData.id}`, formData);
    }else{
      send = await insertDataJson(route, formData);
    }

    if (send.code === 201) {
      setStatus({
        status: true,
        message: "Se ha guardado la ciudad satisfactoriamente",
        label: "success",
      });
      setFormData(emptyData);
      setIsEdit(false);
      getAdvertisings();
      return;
    }

    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const deleteUser = async (row) =>{
    const send = await deleteItem(`Advertisings/${row.id}`);
    if (send.code === 200) {
      setStatus({
        status: true,
        message: "Se ha eliminado el usuario",
        label: "success",
      });
      setFormData(emptyData);
      setIsEdit(false);
      getAdvertisings();
      return;
    }
    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });

  }

  const editForm = (row) =>{
    setStatus({status: null, message: ""});
    setIsEdit(true);
    delete row.image;
    setFormData({
      ...row,
      order: row.order.toString()
    });
  }

  useEffect(()=>{
    getAdvertisings();
  }, [])

  const columns = [
      {
          name: 'ID',
          selector: row => row.id,
          compact: true,
      },
      {
        name: 'Cliente',
        selector: row => row.customer,
        compact: true,
        grow:2
      },
      {
        name: 'Ubicación',
        selector: row => row.location,
        compact: true,
      },
      {
        name: 'Orden',
        selector: row => row.order,
        compact: true,
      },
      {
        name: 'Imagen',
        selector: row => <img width="100" src={row.image} />,
      },
      {
        name: 'Editar',
        selector: row => <Icon onClick={()=>editForm(row)} style={{ cursor: "pointer"}} fontSize="medium" color="primary">edit</Icon>,
        compact: true
      },
      {
      name: 'Eliminar',
      selector: row => <Icon  onClick={()=>deleteUser(row)} style={{ cursor: "pointer"}} fontSize="medium" color="error">delete</Icon>,
      compact: true
     },
  ];

  const tableData = {
    columns,
    data,
  };

  const dataLocations = [
    {
      value: 'Inicio',
      label: 'Inicio',
    },
    {
      value: 'Encuentranos',
      label: 'Encuentranos',
    }
  ]

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Publicidad</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                status.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={status.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {status.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={4} px={1}>
                  <SoftTypography variant="caption">Cliente(*):</SoftTypography>
                  <Input 
                    name = "customer"
                    value = {formData.customer}
                    onChange={setState}
                    className= {errorFields.includes("customer") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={4} px={1}>
                  <SoftTypography variant="caption">Imagen:</SoftTypography>
                  <Input 
                    name = "image"
                    type ="file"
                    onChange={event => handleFile(event)}
                    className= {errorFields.includes("image") ? 'error-field' : null}
                  />
                  <p style={{ fontSize: 14 }} className="mt-2">Medidas sugeridas 300X450 pixeles</p>
                </Grid>
                <Grid xs={12} md={4} px={1}>
                    <SoftTypography variant="caption">Ubicación(*): </SoftTypography>
                    <Select 
                    className= {`z-101 ${errorFields.includes("location") ? 'error-field' : null} `}
                    options={dataLocations} 
                    onChange={selectLocation}
                    name = 'location'
                    value={dataLocations.filter(item => item.value === formData.location)[0]}
                />
                </Grid>
                <Grid xs={12} md={3} px={1}>
                  <SoftTypography variant="caption">Orden(*): </SoftTypography>
                  <Input 
                  name = "order"
                  value = {formData.order}
                  onChange={setState}
                  className= {errorFields.includes("order") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Url:</SoftTypography>
                  <Input 
                    name = "url"
                    value = {formData.url}
                    onChange={setState}
                    className= {errorFields.includes("url") ? 'error-field' : null}
                  />
                </Grid>
                <Grid container mt={2} xs={12} md={3} spacing={2} >
                  <Grid container item md={12} justifyContent='flex-end' alignContent="center">
                      <SoftButton
                          md={12}
                          variant="gradient"
                          color= "primary"
                          size="medium"
                          onClick = {()=>sendForm(false)}
                      >
                          {isEdit ? 'Actualizar' : 'Agregar'} publicidad
                      </SoftButton>
                </Grid>
              </Grid>
              </Grid>
              <Divider />
              <Grid md={12} m={0} p={0} >
                <SoftBox  md={12}>
                  <Grid container p={0} md={12}>
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                      filter={true}
                    >
                      <DataTable
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'Registros por página:', 
                            rangeSeparatorText: 'de', 
                            noRowsPerPage: false, 
                            selectAllRowsItem: true, 
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
                      />
                    </DataTableExtensions>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}
export default DataForm;
