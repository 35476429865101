
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { Divider, Grid, Icon, Modal } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useState, useEffect } from "react";

import 'react-data-table-component-extensions/dist/index.css';
import { insertDataJson, getData } from "tools/helpers";
import DataFormCity from "./dataForm";

function City() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
          <DataFormCity />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default City;
