
import { Icon } from "@mui/material";
import Logout from "auth/Logout";
import Profiles from "layouts/profiles";

const routes = [
  {
    type: "collapse",
    name: "Mi Perfil",
    key: "mi-perfil",
    route: "/profiles",
    icon: <Icon color="white">person_3</Icon>,
    component: <Profiles />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Cerrar Sesión",
    key: "login",
    route: "logout",
    icon: <Icon color="white">logout</Icon>,
    component: <Logout />,
    noCollapse: true,
  },
];

export default routes;
