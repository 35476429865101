import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsApp from "@mui/icons-material/WhatsApp";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";


import SoftAlert from 'components/SoftAlert';
import { Icon, Modal } from '@mui/material';
import { insertData, getData } from 'tools/helpers';
import { DOMAIN_API } from 'constans';
import SoftButton from 'components/SoftButton';
import DataForm from './dataForm';
import { validateForm, updateItem } from 'tools/helpers';
import { insertDataJson } from 'tools/helpers';

function Overview() {
  const [profile, setProfile] = useState({});
  const [status, setStatus] = useState({status: null, message: ""});
  const [statusForm, setStatusForm] = useState({status: null, message: ""});
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [dataCities, setDataCities] = useState([]);
  const [imagePreview, setImagePreview] = useState(false);
    const emptyData = {
    slug : '',
    name : '',
  }
  const [formData, setFormData] = useState(emptyData);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [errorFields, setErrorsFields] = useState([]);


  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function selectCity(detalle_producto) {
    setFormData({
      ...formData,
      city_id: detalle_producto.value,
    })
  }

  const isValidFileType = (file) => {
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const fileExtension = `.${file.name.split('.').pop()}`;
    return allowedExtensions.includes(fileExtension);
  };
  
  const acceptFormat = '.jpg, .jpeg, .png';
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    setStatus({
      status: false,
      message: "",
      label: "",
    });
    setFile(null);
    if (file) {
      if(!isValidFileType(file)){
        setStatus({
          status: true,
          message: `Formato incorrecto, aceptables: ${acceptFormat}`,
          label: "warning",
        });
        return;
      }

      const options = {
        maxSizeMB: 2, 
        maxWidthOrHeight: 1024, 
        useWebWorker: true, 
      };

      try {
        const compressedBlob = await imageCompression(file, options);
        const extensions = compressedBlob.name.slice(((compressedBlob.name.lastIndexOf(".") - 1) >>> 0) + 2);
        uploadFile(compressedBlob, extensions);
      } catch (error) {
        console.error("Error al comprimir la imagen", error);
        setStatus({
          status: true,
          message: "Error al comprimir la imagen",
          label: "error",
        });
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: acceptFormat,
  });

  const getProfile = async () => {
    const route = `Profile/${slug}`;
    const send = await getData(route);
    if (send.code === 200) {
      setFormData(send.data);
      setProfilePhoto(send.principal_photo);
    }
  };

  const getProfilePhotos = async () => {
    const route = `Profile-photos/${slug}`;
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data);
    }
  };

  const getCities = async () => {
    const route = `City`;
    const send = await getData(route);
    let list_cities = [];
    if(send.code === 200){
      send.data.map(item=>{
        list_cities.push({
          value: item.id,
          label: item.description,        
        })
      })
      setDataCities(list_cities);
    }
};

  const { slug } = useParams();
  const requiredFields = ['file', 'slug'];
  const uploadFile = async (file, extensions) => {
    setStatus({
      status: false,
      message: "",
      label: "",
    });

    setStatus({
      status: true,
      message: "Subiendo...",
      label: "info",
    });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('slug', slug);
    formData.append('extensions', extensions);

    const route = "Upload-photo";
    const send = await insertData(route, formData);

    if (send.code === 201) {
      setStatus({
        status: true,
        message: 'Se ha subido la foto correctamente',
        label: "success",
      });

      setFile(null);
      getProfilePhotos();
      return;
    }

    if (send.status === "error") {
      requiredFields.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            console.log(item)
            setStatus({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }

    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  };

  const getPreview = (image) =>{
    setImagePreview(image);
    setOpen(true);
  }

  const user = JSON.parse(localStorage.getItem("USER_APP_SERVICES"));
  const requiredFieldsForm = ['name'];
  const sendForm = async() =>{
    const validationErrors = validateForm(formData, requiredFieldsForm);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Profile`;
    const send = await updateItem(`${route}/${formData.id}`, formData);

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha guardado el perfil satisfactoriamente",
        label: "success",
      });
      setOpenForm(false);
      getProfile();
      return;
    }

    if (send.status === "error") {
      requiredFields.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            console.log(item)
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }
  }


  const setPrivate = async($id) =>{
    setStatusForm({status: true, message: "Guardando..."});
    const newData = {
      photo_id: $id
    }
    const route = `Set-private-photo`;  
    const send = await insertDataJson(route, newData);

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha cambiado el estatus de la foto",
        label: "success",
      });
      getProfilePhotos();
      return;
    }

    setStatusForm({
      status: true,
      message: 'Ha ocurrido algo y no se actualizó',
      label: "error",
    });
  }

  const setPrincipalPhoto = async($id) =>{
    setStatusForm({status: true, message: "Guardando..."});
    const newData = {
      photo_id: $id,
      profile_id: formData.id
    }
    const route = `Set-principal-photo`;  
    const send = await insertDataJson(route, newData);

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha cambiado el estatus de la foto",
        label: "success",
      });
      getProfilePhotos();
      getProfile();
      return;
    }

    setStatusForm({
      status: true,
      message: 'Ha ocurrido algo y no se actualizó',
      label: "error",
    });
  }

  useEffect(()=>{
    getProfile();
    getProfilePhotos();
    getCities();
  },[])

  const dropzoneStyles = {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1
  };

  return (
    <DashboardLayout>
      <Header 
      profilePhoto = {profilePhoto}
      formData = {formData}
      />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <ProfileInfoCard
              title="Información perfil (Editar)"
              description= {formData.description}
              info={{
                Nombre: formData.name,
                Edad: formData.age,
                Nacionalidad: formData.nationality,
                Altura: formData.height,
                Peso: formData.weight,
                Medidas: formData.measures,
                Idiomas: formData.languages,
                Estado: formData.city?.state?.description,
                Ciudad: formData.city?.description,
              }}
              action={{ route: "", tooltip: "Editar Contácto" }}
              setOpenForm = {setOpenForm}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProfileInfoCard
              title="Datos Contácto (Editar)"
              description = "Estos datos se muestran en el perfil"
              info={{
                Precio_1_Hora: formData.price_hours,
                Precio_2_Hora: formData.price_two_hours,
                Precio_Noche: formData.price_night,
                Horario: formData.schedule,
                Teléfonos: formData.phones,
                emailPrincipal: formData.email_principal,
                Emails: formData.emails,
              }}
              social={[
                {
                  link: formData.facebook,
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                // {
                //   link: formData.x,
                //   icon: <TwitterIcon />,
                //   color: "twitter",
                // },
                {
                  link: formData.whatsapp,
                  icon: <WhatsApp />,
                  color: "whatsapp",
                },
              ]}
              action={{ route: "", tooltip: "Editar Perfil" }}
              setOpenForm = {setOpenForm}
            />
          </Grid>
          {/* <Grid item xs={12} md={4} xl={4}>
            <ProfilesList title="Últimos Comentarios" profiles={profilesListData} />
          </Grid> */}
        </Grid>
      </SoftBox>
      <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
                Mis fotos
              </SoftTypography>
            </SoftBox>
            <Grid xs={12}>
              {
                status.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={status.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {status.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
            </Grid>
            <SoftBox mb={1}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Puedes decidir cuales mostrar en tu perfil.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
            <Grid container spacing={3}>
              {
                data.map(item=>
                <Grid item xs={6} md={3} xl={3} key = {item.id}>
                  <DefaultProjectCard
                  preview = {getPreview}
                  image={`${DOMAIN_API}Profile-photo/${item.source}`}
                  action={{
                    type: "internal",
                    color: "info",
                    label: "Hacer Privado",
                  }}
                  authors = {[]}
                  key = {`item-${item.id}`}
                  item = {item}
                  action1 = {setPrincipalPhoto}
                  action2 = {setPrivate}
                />
                </Grid>)
              }
              <Grid container p={3} xs={12} md={3} xl={3}>
                <SoftBox style={{ heigth: '100%' }} xs={12} {...getRootProps()} style={dropzoneStyles}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                     <div>
                     <Icon  fontSize="large" size="lg" color="gray">expand_more</Icon>
                     <p>Soltar aquí</p>
                   </div>
                  ) : (
                    <div>
                      <Icon fontSize="large" style={{ width: 200 }} color="gray">photo</Icon>
                      <p>Subir foto</p>
                    </div>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Modal
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={style} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
              <Grid>
                <img style={{ width: '100%' }} src={imagePreview}  />
              </Grid>
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item  />
              <Grid item >
                <SoftButton
                  md={6}
                  variant="gradient"
                  color= "dark"
                  size="medium"
                  onClick = {()=>setOpen(false)}
                >
                  Cerrar
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <Modal
          open={openForm}
          onClose={()=>setOpenForm(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={style} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
              <DataForm
              formData = {formData} 
              setState = {setState}
              selectCity = {selectCity}
              errorFields = {errorFields} 
              statusForm = {statusForm}
              dataCities = {dataCities}
              />
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item >
                  <SoftButton
                      md={6}
                      variant="gradient"
                      color= "primary"
                      size="medium"
                      onClick = {()=>sendForm(false)}
                  >
                      Guardar
                </SoftButton>
              </Grid>
              <Grid item >
                <SoftButton
                  md={6}
                  variant="gradient"
                  color= "dark"
                  size="medium"
                  onClick = {()=>setOpenForm(false)}
                >
                  Cerrar
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '90%',
  maxWidth: '900px',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default Overview;
