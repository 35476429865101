
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";

// Data
import { Grid, Input } from "@mui/material";

import SoftAlert from "components/SoftAlert";

function DataForm({formData, setState, errorFields, statusForm}) {

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Nuevo perfil</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                statusForm.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={statusForm.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {statusForm.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Nombre *:</SoftTypography>
                  <Input 
                    name = "name"
                    value = {formData.name}
                    onChange={setState}
                    className= {errorFields.includes("name") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Perfil*: </SoftTypography>
                  <Input 
                    name = "slug"
                    value = {formData.slug}
                    onChange={setState}
                    disabled
                    className= {errorFields.includes("slug") ? 'error-field' : null}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}

DataForm.propTypes = {
  formData: PropTypes.object,
  setState: PropTypes.func,
  errorFields: PropTypes.string,
  statusForm: PropTypes.string,
};

export default DataForm;
