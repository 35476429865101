import React from 'react';
import { Navigate } from 'react-router-dom';
import SignIn from "layouts/authentication/sign-in";
 
function RouteGuard2(){
   // check user has JWT token
   let routeCustom = '/profiles';
   const user = JSON.parse(localStorage.getItem("USER_APP_SERVICES"));
   if(localStorage.getItem("USER_APP_SERVICES")){
      if(user.type === 'admin'){
      	routeCustom = '/profiles';
      }
      if(user.type === 'cedis'){
      	routeCustom = '/pedidos';
      }
      return <Navigate to={routeCustom} />
   }
   return <SignIn />
};

export default RouteGuard2;