
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";
import Select from 'react-select';

// Data
import { Grid, Input } from "@mui/material";

import SoftAlert from "components/SoftAlert";

function DataForm({formData, setState, selectCity, errorFields, statusForm, dataCities}) {

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Actualizar información del perfil</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                statusForm.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={statusForm.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {statusForm.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Nombre *:</SoftTypography>
                  <Input 
                    name = "name"
                    value = {formData.name}
                    onChange={setState}
                    className= {errorFields.includes("name") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Perfil*: </SoftTypography>
                  <Input 
                    name = "slug"
                    value = {formData.slug}
                    onChange={setState}
                    disabled
                    className= {errorFields.includes("slug") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={12} px={1}>
                  <SoftTypography variant="caption">Sobre mí:</SoftTypography>
                  <textarea
                    rows="4"
                    style = {{ width : '100%', borderColor: '#cbcbcb', padding: 5 }}
                    name = "description"
                    value = {formData.description}
                    onChange={setState}
                    className= {errorFields.includes("description") ? 'error-field' : null}
                  ></textarea>
                </Grid>
                <Grid xs={12} md={4} px={1}>
                  <SoftTypography variant="caption">Precio 1 Hora:</SoftTypography>
                  <Input 
                    name = "price_hours"
                    value = {formData.price_hours}
                    onChange={setState}
                    className= {errorFields.includes("price_hours") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={4} px={1}>
                  <SoftTypography variant="caption">Precio 2 Hora:</SoftTypography>
                  <Input 
                    name = "price_two_hours"
                    value = {formData.price_two_hours}
                    onChange={setState}
                    className= {errorFields.includes("price_two_hours") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={4} px={1}>
                  <SoftTypography variant="caption">Precio Noche:</SoftTypography>
                  <Input 
                    name = "price_night"
                    value = {formData.price_night}
                    onChange={setState}
                    className= {errorFields.includes("price_night") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Edad:</SoftTypography>
                  <Input 
                    name = "age"
                    value = {formData.age}
                    onChange={setState}
                    className= {errorFields.includes("age") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Nacionalidad:</SoftTypography>
                  <Input 
                    name = "nationality"
                    value = {formData.nationality}
                    onChange={setState}
                    className= {errorFields.includes("nationality") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Altura:</SoftTypography>
                  <Input 
                    name = "height"
                    value = {formData.height}
                    onChange={setState}
                    className= {errorFields.includes("height") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Peso:</SoftTypography>
                  <Input 
                    name = "weight"
                    value = {formData.weight}
                    onChange={setState}
                    className= {errorFields.includes("weight") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Medidas:</SoftTypography>
                  <Input 
                    name = "measures"
                    value = {formData.measures}
                    onChange={setState}
                    className= {errorFields.includes("measures") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Idiomas:</SoftTypography>
                  <Input 
                    name = "languages"
                    value = {formData.languages}
                    onChange={setState}
                    className= {errorFields.includes("languages") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Ciudad:</SoftTypography>
                  <Select 
                    className="z-101"
                    options={dataCities} 
                    onChange={selectCity}
                    value={dataCities.filter(item => item.value === formData.city?.id)[0]}
                />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Horario:</SoftTypography>
                  <Input 
                    name = "schedule"
                    value = {formData.schedule}
                    onChange={setState}
                    className= {errorFields.includes("schedule") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Teléfonos:</SoftTypography>
                  <Input 
                    name = "phones"
                    value = {formData.phones}
                    onChange={setState}
                    className= {errorFields.includes("phones") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} mt={2}>
                  <SoftTypography variant="caption">Emails:</SoftTypography>
                  <Input 
                    name = "emails"
                    value = {formData.emails}
                    onChange={setState}
                    className= {errorFields.includes("emails") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Facebook:</SoftTypography>
                  <Input 
                    name = "facebook"
                    value = {formData.facebook}
                    onChange={setState}
                    className= {errorFields.includes("facebook") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Whatsapp:</SoftTypography>
                  <Input 
                    type= 'number'
                    name = "whatsapp"
                    value = {formData.whatsapp}
                    onChange={setState}
                    className= {errorFields.includes("whatsapp") ? 'error-field' : null}
                  />
                </Grid>
                <h5><small>* Escribír solo números</small></h5>
              </Grid>
              <Grid xs={12} md={6} px={1} mt={2}>
                <h5><small>Necesario para iniciar sesión</small></h5>
                <SoftTypography variant="caption">Email principal (usuario para el inicio de sesión):</SoftTypography>
                <Input 
                  type = "email"
                  name = "email_principal"
                  value = {formData.email_principal}
                  onChange={setState}
                  className= {errorFields.includes("email_principal") ? 'error-field' : null}
                />
              </Grid>
              <Grid xs={12} md={6} px={1}>
                <SoftTypography variant="caption">Contraseña:</SoftTypography>
                <Input 
                  name = "password"
                  value = {formData.password}
                  onChange={setState}
                  className= {errorFields.includes("password") ? 'error-field' : null}
                />
                <SoftTypography variant="caption">*Dejar en blanco si no desea editar la contraseña</SoftTypography>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}

DataForm.propTypes = {
  formData: PropTypes.object,
  setState: PropTypes.func,
  errorFields: PropTypes.string,
  statusForm: PropTypes.string,
};

export default DataForm;
