
const baseRuta = "https://api.chicasvip.mx/api/";
// const baseRuta = "http://127.0.0.1:8000/api/";

export async function insertData(ruta, objeto) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_APP_SERVICES");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        //'Content-Type': 'multipart/form-data'
      },
      body: objeto,
    };
    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function insertDataJson(ruta, objeto) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_APP_SERVICES");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objeto),
    };
    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function getData(ruta) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_APP_SERVICES");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function updateItem(ruta, objeto) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_APP_SERVICES");
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objeto),
    };

    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "danger", message: "Ha ocurrido algo:" + err };
  }
}

export async function deleteItem(ruta) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_APP_SERVICES");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "danger", message: "Ha ocurrido algo:" + err };
  }
}

export async function login(ruta, objeto) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objeto),
    };
    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function calcularTotal(venta, setVenta, detalleventa) {
  const sumaPrecios = await detalleventa.reduce(
    (prev, next) => prev + parseFloat(next.importe_partida),
    0
  );
  console.log(sumaPrecios);
  if (sumaPrecios > 0) {
    await setVenta({
      ...venta,
      subtotal: sumaPrecios,
      iva: parseFloat(sumaPrecios * 0.16),
      total: parseFloat(sumaPrecios * 1.16),
    });
  }
}

export async function searchTable(list, item, field, field2, field3) {
  item = item.replace(/\s/g, '');
  console.log(item.replace(/\s/g, ''))
  const resultado = list.filter((resul) => {
    if (resul[field]) {
    } else {
      resul[field] = "";
    }
    if (resul[field2]) {
    } else {
      resul[field2] = "";
    }
    if (resul[field3]) {
    } else {
      resul[field3] = "";
    }

    if (
      resul[field]
        .replace(/\s/g, '')
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(item.toLowerCase()) ||
      resul[field2]
      .replace(/\s/g, '')
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(item.toLowerCase()) ||
      resul[field3]
        .replace(/\s/g, '')
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(item.toLowerCase())
    ) {
      return resul;
    }
  });
  return resultado;
}

export function restarFecha(fecha1, fecha2) {
  let fecha = Math.abs(new Date(fecha2) - new Date(fecha1));
  let minutos = Math.floor(fecha / 1000 / 60);
  let total_tiempo_min = minutos;
  let tipo = "min";
  if (minutos >= 60) {
    minutos = (minutos / 60).toFixed(2);
    tipo = "hrs";
  }
  let datos = {
    tiempo: minutos,
    tipo: tipo,
    total_tiempo_min: total_tiempo_min,
  };
  return datos;
}

export function getDateNow() {
  let dateNow = new Date();
  const year = dateNow.getFullYear();
  let month = dateNow.getMonth();
  let day = dateNow.getDate();
  const locale = "es-MX";
  const monthDescription = dateNow.toLocaleString(locale, { month: "long" });
  month = dateNow.toLocaleDateString("es-MX",{ month:'numeric'});

  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  
  return {
    year: year,
    month: month,
    day: day,
    fullDate: `${year}-${month}-${day}`,
    monthDescription: monthDescription,
  };
}

export function getTimeNow(date) {
  let dateFull =
    new Date(date).getHours() +
    ":" +
    new Date(date).getMinutes() +
    ":" +
    new Date(date).getSeconds();
  return dateFull;
}


export function getDateLarge(date) {
    const newDate = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return newDate.toLocaleDateString('es-MX', options)
}

export function getHoursLarge(datetime) {
    const newDatetime = new Date(datetime);
	return newDatetime.getHours('es-MX')
}

export function parseCurrency(value) {
    const formatCurrency = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      });
      
      return formatCurrency.format(value);
}

export function validateForm(obj, requiredFields){
  const errors = [];
  requiredFields.forEach(field => {
    if (!obj.hasOwnProperty(field)) {
      errors.push(field);
    } else if (! obj[field] ) {
      errors.push(field);
    }
    else if (obj[field].trim() === '') {
      errors.push(field);
    }
  });
  return errors;
}

export function revertIVAProduct(unit_price){
  return parseFloat(unit_price) / 1.16;
}

export function validateDateMinorDate(date_end){
  let dateNow = new Date();
  const date_2 = new Date(date_end);
  dateNow.setHours(0, 0, 0, 0);
  date_2.setHours(0, 0, 0, 0);

  return dateNow <= date_2;
}

export function generateSlug(name){
  const slug = name.toLowerCase().replace(/\s+/g, '-');
  const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000);
  const finalSlug = `${slug}-${randomNumber}`;
  return finalSlug;
}