import React from 'react';
import { Outlet , Navigate } from 'react-router-dom';
 
function RouteGuard(){
   // check user has JWT token
   if(localStorage.getItem("USER_APP_SERVICES")){
      return <Outlet />
   }

   return <Navigate to="/login" />
};

export default RouteGuard;