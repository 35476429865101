
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";

// Data
import { Divider, Grid, Input, Icon, Modal } from "@mui/material";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import Select from 'react-select';

import SoftAlert from "components/SoftAlert";
import { useState, useEffect } from "react";
import SoftButton from "components/SoftButton";

import { insertDataJson, getData, updateItem, deleteItem, validateForm } from "tools/helpers";

function DataForm() {
  const emptyData = {
    description : '',
    state_id : 0,
  }
  const [formData, setFormData] = useState(emptyData);

  const [status, setStatus] = useState({status: null, message: ""});
  const [isEdit, setIsEdit] = useState(false);
  const [errorFields, setErrorsFields] = useState("");
  const [data, setData] = useState([]);
  const [dataStates, setDataStates] = useState([]);

  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e, name = "", value = "") =>{
    setFormData({
        ...formData, 
        state_id: e.id
      });
  }

  const getStates = async () => {
    const route = `State`;
    const send = await getData(route);
    if (send.code === 200) {
      setDataStates(send.data);
    }
};

  const getCities = async () => {
      const route = `City`;
      const send = await getData(route);
      if (send.code === 200) {
        setData(send.data);
      }
  };

  const sendForm = async() =>{
    let requiredFields = ['description'];
    const validationErrors = validateForm(formData, requiredFields);
    if(formData.state_id === 0){
      validationErrors.push('state_id');
    }
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatus({status: true, message: "Guardando..."});
    const route = `City`;
    let send = null;

    if(isEdit){
      send = await updateItem(`${route}/${formData.id}`, formData);
    }else{
      send = await insertDataJson(route, formData);
    }

    if (send.code === 201) {
      setStatus({
        status: true,
        message: "Se ha guardado la ciudad satisfactoriamente",
        label: "success",
      });
      setFormData(emptyData);
      setIsEdit(false);
      getCities();
      return;
    }

    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const deleteUser = async (row) =>{
    const send = await deleteItem(`City/${row.id}`);
    if (send.code === 200) {
      setStatus({
        status: true,
        message: "Se ha eliminado el usuario",
        label: "success",
      });
      setFormData(emptyData);
      setIsEdit(false);
      getCities();
      return;
    }
    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });

  }

  const editForm = (row) =>{
    setStatus({status: null, message: ""});
    setIsEdit(true);
    setFormData(row);
  }

  useEffect(()=>{
    getStates();
    getCities();
  }, [])

  const columns = [
      {
          name: 'ID',
          selector: row => row.id,
          compact: true,
      },
      {
        name: 'Ciudad',
        selector: row => row.description,
        compact: true,
        grow:2
    },
      {
        name: 'Estado',
        selector: row => row.state.description,
        compact: true,
      },
      {
        name: 'Editar',
        selector: row => <Icon onClick={()=>editForm(row)} style={{ cursor: "pointer"}} fontSize="medium" color="primary">edit</Icon>,
        compact: true
      },
      {
      name: 'Eliminar',
      selector: row => <Icon  onClick={()=>deleteUser(row)} style={{ cursor: "pointer"}} fontSize="medium" color="error">delete</Icon>,
      compact: true
     },
  ];

  const tableData = {
    columns,
    data,
  };

  const getStatesByCountry = dataStates.find(option => option.id === formData.state_id);

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Ciudades</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                status.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={status.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {status.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={5} px={1}>
                  <SoftTypography variant="caption">Nombre de la ciudad:</SoftTypography>
                  <Input 
                    name = "description"
                    value = {formData.description}
                    onChange={setState}
                    className= {errorFields.includes("description") ? 'error-field' : null}
                  />
                </Grid>
                  <Grid xs={12} md={4} px={1}>
                    <SoftTypography variant="caption">Estado: </SoftTypography>
                    <Select className={`z-101 ${errorFields.includes("state_id") ? 'error-field' : null}`}
                    options={dataStates} placeholder="Estado"
                    value={getStatesByCountry || ''} onChange={(e) => handleChange(e,"state","state_id")}
                    getOptionValue={(option) => `${option.id}`}
                    getOptionLabel={(option) => `${option.description}`}
                  />
                  </Grid>
                <Grid container mt={2} xs={12} md={3} spacing={2} >
                  <Grid container item md={12} justifyContent='flex-end' alignContent="center">
                      <SoftButton
                          md={12}
                          variant="gradient"
                          color= "primary"
                          size="medium"
                          onClick = {()=>sendForm(false)}
                      >
                          {isEdit ? 'Actualizar' : 'Agregar'} ciudad
                      </SoftButton>
                </Grid>
              </Grid>
              </Grid>
              <Divider />
              <Grid md={12} m={0} p={0} >
                <SoftBox  md={12}>
                  <Grid container p={0} md={12}>
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                      filter={true}
                    >
                      <DataTable
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'Registros por página:', 
                            rangeSeparatorText: 'de', 
                            noRowsPerPage: false, 
                            selectAllRowsItem: true, 
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
                      />
                    </DataTableExtensions>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}
export default DataForm;
