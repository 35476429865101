
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";

// Data
import { Divider, Grid, Input, Icon, Modal } from "@mui/material";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import Select from 'react-select';

import SoftAlert from "components/SoftAlert";
import { useState, useEffect } from "react";
import SoftButton from "components/SoftButton";

import { insertDataJson, getData, updateItem, deleteItem } from "tools/helpers";

function DataForm() {
  const emptyData = {
    name : '',
    email : '',
    password : '',
    type: 'user'
  }
  const [dataUser, setDataUser] = useState(emptyData);

  const [status, setStatus] = useState({status: null, message: ""});
  const [isEdit, setIsEdit] = useState(false);
  const [errorFields, setErrorsFields] = useState("");
  const [data, setData] = useState([]);

  const setState = (e) => {
    setDataUser({
      ...dataUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
      setDataUser({...dataUser, [e.target.name]:e.target.value});
    }else{
      setDataUser({...dataUser, [value]:e[value], [name]:e});
    }
  }

  const getUsers = async () => {
      const route = `User`;
      const send = await getData(route);
      if (send.code === 200) {
        setData(send.data);
      }
  };

  const sendForm = async() =>{
    let requiredFields = ['name', 'email', 'password'];
    if(isEdit){
      requiredFields = ['name', 'email'];
    }

    const validationErrors = validateForm(dataUser, requiredFields);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatus({status: true, message: "Guardando..."});
    const route = `User`;
    let send = null;

    if(isEdit){
      send = await updateItem(`${route}/${dataUser.id}`, dataUser);
    }else{
      send = await insertDataJson(route, dataUser);
    }

    if (send.code === 201) {
      setStatus({
        status: true,
        message: "Se ha guardado el cliente satisfactoriamente",
        label: "success",
      });
      setDataUser(emptyData);
      setIsEdit(false);
      getUsers();
      return;
    }

    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const setStatusUser = async(row) =>{
    const newData = {
      id: row.id,
      status: parseInt(row.status, 10) === 0 ? 1: 0
    }
    const route = `User`;
    const send = await updateItem(`${route}/${row.id}`, newData);

    if (send.code === 201) {
      setStatus({
        status: true,
        message: "Se ha cambiado el estatus del usuario",
        label: "success",
      });
      setDataUser(emptyData);
      getUsers();
      return;
    }

    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const deleteUser = async (row) =>{
    const send = await deleteItem(`User/${row.id}`);
    if (send.code === 200) {
      setStatus({
        status: true,
        message: "Se ha eliminado el usuario",
        label: "success",
      });
      setDataUser(emptyData);
      setIsEdit(false);
      getUsers();
      return;
    }
    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });

  }

  const validateForm = (obj, requiredFields) =>{
    const errors = [];
    setErrorsFields([]);
    console.log(obj)
    requiredFields.forEach(field => {
      if (!obj.hasOwnProperty(field)) {
        errors.push(field);
      } else if (! obj[field] ) {
        errors.push(field);
      }
      else if (obj[field].trim() === '') {
        errors.push(field);
      }
    });
  
    if (errors.length > 0) {
      setErrorsFields(errors);
    }

    return errors;
  }

  const editForm = (row) =>{
    setStatus({status: null, message: ""});
    setIsEdit(true);
    setDataUser(row);
  }

  useEffect(()=>{
    getUsers();
  }, [])

  const columns = [
      {
          name: 'ID',
          selector: row => row.id,
          compact: true,
      },
      {
        name: 'Nombre',
        selector: row => row.name,
        compact: true,
        grow:2
    },
      {
        name: 'Correo',
        selector: row => row.email,
        compact: true,
      },
      {
        name: 'Tipo',
        selector: row => row.type,
        compact: true
      },
      {
        name: 'Editar',
        selector: row => <Icon onClick={()=>editForm(row)} style={{ cursor: "pointer"}} fontSize="medium" color="primary">edit</Icon>,
        compact: true
      },
      {
        name: 'Deshabilitar',
        selector: row => <Icon onClick={()=>setStatusUser(row)} style={{ cursor: "pointer"}} fontSize="medium" color={ parseInt(row.status, 10) === 0 ? 'success' : 'error' }>{ parseInt(row.status, 10) === 0 ? 'visibility' : 'visibility_off' }</Icon>,
        compact: true
       },
      {
      name: 'Eliminar',
      selector: row => <Icon  onClick={()=>deleteUser(row)} style={{ cursor: "pointer"}} fontSize="medium" color="error">delete</Icon>,
      compact: true
     },
  ];

  const tableData = {
    columns,
    data,
  };

  const types_users = [
    { type: 'admin', label: 'Administrador'},
    { type: 'user', label: 'Usuario'},
    { type: 'profile', label: 'Perfil'},
  ];
  const getTypeUsers = types_users.find(option => option.type === dataUser.type);

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Usuarios Internos</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                status.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={status.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {status.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Nombre completo *:</SoftTypography>
                  <Input 
                    name = "name"
                    value = {dataUser.name}
                    onChange={setState}
                    className= {errorFields.includes("name") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Correo *: </SoftTypography>
                  <Input 
                    type="email"
                    name = "email"
                    value = {dataUser.email}
                    onChange={setState}
                    className= {errorFields.includes("email") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Contraseña *: </SoftTypography>
                  <Input 
                    name = "password"
                    value = {dataUser.password}
                    onChange={setState}
                    className= {errorFields.includes("password") && ! isEdit ? 'error-field' : null}
                  />
                  <SoftTypography variant="caption">*Dejar en blanco si no desea editar la contraseña</SoftTypography>
                </Grid>
                  <Grid xs={12} md={6} px={1}>
                    <SoftTypography variant="caption">Tipo: </SoftTypography>
                    <Select className={`z-101`}
                    options={types_users} placeholder="Tipo"
                    value={getTypeUsers || ''} onChange={(e) => handleChange(e,"value","type")}
                    getOptionValue={(option) => `${option.type}`}
                    getOptionLabel={(option) => `${option.label}`}
                  />
                  </Grid>
                <Grid container mt={2} xs={12} md={6} spacing={2} >
                  <Grid container item md={12} justifyContent='flex-end' alignContent="center">
                      <SoftButton
                          md={12}
                          variant="gradient"
                          color= "primary"
                          size="medium"
                          onClick = {()=>sendForm(false)}
                      >
                          {isEdit ? 'Actualizar' : 'Agregar'} usuario
                      </SoftButton>
                </Grid>
              </Grid>
              </Grid>
              <Divider />
              <Grid md={12} m={0} p={0} >
                <SoftBox md={12}>
                  <Grid container p={0} md={12}>
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                      filter={true}
                    >
                      <DataTable
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'Registros por página:', 
                            rangeSeparatorText: 'de', 
                            noRowsPerPage: false, 
                            selectAllRowsItem: true, 
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
                      />
                    </DataTableExtensions>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}
export default DataForm;
