/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/slider-bg-2.jpg";
import Logo from "assets/images/logo.png"

import { useNavigate } from "react-router-dom";
import { login } from "../../../tools/helpers";
import SoftAlert from "components/SoftAlert";
import { Icon } from "@mui/material";

function SignUp() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  
  const { email, password } = user;
  const [showPassword, setShowPassword] = useState(false);

  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const setState = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const submitLogin = async (e) => {
    setMessage({
      status: true,
      label: "info",
      message: "Iniciando...",
    });

    const sendUser = await login("login", user);
    if (sendUser.status === "success") {
      const user = JSON.stringify(sendUser.user);
      await localStorage.setItem("STORAGE_KEY_APP_SERVICES", sendUser.access_token);
      await localStorage.setItem("USER_APP_SERVICES", user);
      setMessage({
        status: false,
        message: "",
      });
      navigate("/profiles");
      return;
    }

    setMessage({
      status: true,
      label: "error",
      message: sendUser.message,
    });

    navigate("/login");
  };

  const logout = async () => {
    await localStorage.removeItem("STORAGE_KEY_APP_SERVICES");
    await localStorage.removeItem("USER_APP_SERVICES");
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <BasicLayout
      title="¡Bienvenido!"
      description="Inicie sesión con su usuario y contraseña"
      image={curved6}
    >
      <Card>
        <SoftBox p={3} textAlign="center">
          <img width={200} src={Logo} />
        </SoftBox>
        <SoftBox textAlign="center">
          <SoftTypography variant="h9" fontWeight="medium">
            Inicio de Sesión
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput onChange={setState} value={email} name="email" type="email" placeholder="Email" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput onChange={setState} value={password} name="password" type={showPassword ? 'text' : 'password'} placeholder="Password" />
                <div className="eye-password">
                  <Icon onClick={()=>setShowPassword(!showPassword)} fontSize="small">{showPassword ? 'visibility' : 'visibility_off'}</Icon>
                </div>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton 
                onClick = {()=>submitLogin()}
                variant="gradient" color="dark" fullWidth>
                acceder
              </SoftButton>
            </SoftBox>
            {
              message.status ? (
                <SoftBox extAlign="center">
                  <SoftAlert color={message.label}> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      {message.message}
                    </SoftTypography>
                  </SoftAlert>
                </SoftBox>
              ): null
            }
            <SoftBox mt={3} textAlign="center">
              {/* <SoftTypography variant="button" color="text" fontWeight="regular">
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Olvidé mi contraseña
                </SoftTypography>
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
